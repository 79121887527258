// src/pages/Inactive.tsx
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';

interface User {
  email: string;
  subscription_status?: string;
  stripe_subscription_id?: string;
}

export default function Inactive() {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const paymentLink = import.meta.env.VITE_STRIPE_PAYMENT_LINK;
  const billingLink = import.meta.env.VITE_STRIPE_BILLING_LINK;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await fetch('/api/auth/me');
        
        if (res.status === 401) {
          navigate('/welcome');
          return;
        }
        
        if (res.status === 402) {
          navigate('/subscribe');
          return;
        }

        const userData = await res.json();
        setUser(userData);

        if (res.status === 200) {
          navigate('/chat');
          return;
        }
      } catch (err) {
        setError('Failed to load user data');
        console.error(err);
      }
    };

    fetchUser();
  }, [navigate]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navigation />
        <div className="flex items-center justify-center pt-16">
          <div className="bg-red-50 p-4 rounded">
            <p className="text-red-800">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (!paymentLink || !billingLink) {
    console.error('Stripe links not configured');
    return (
      <div className="min-h-screen bg-gray-50">
        <Navigation />
        <div className="flex items-center justify-center pt-16">
          <div className="bg-red-50 p-4 rounded">
            <p className="text-red-800">Configuration error: Stripe links not found</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="flex items-center justify-center pt-16">
        <div className="max-w-md w-full space-y-6 p-8 bg-white rounded-lg shadow">
          <h1 className="text-2xl font-bold text-center">Subscription Inactive</h1>
          <p className="text-gray-600">
            Your subscription has been canceled. You can:
          </p>
          <div className="space-y-4">
            <a
              href={paymentLink}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Reactivate Subscription
            </a>
            <a
              href={billingLink}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              View Past Invoices
            </a>
          </div>

          <div className="mt-8 p-4 bg-gray-100 rounded">
            <h2 className="text-lg font-semibold mb-2">Debug Info:</h2>
            <pre className="whitespace-pre-wrap break-words">
              {JSON.stringify(user, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}

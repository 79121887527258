// src/pages/Welcome.tsx
import { useState, useRef } from 'react';
import { Tag, Zap, Sparkles, Check, ExternalLink } from 'lucide-react';

const gradientAnimation = {
  backgroundSize: '200% 200%',
  animation: 'gradient-animation 3s ease infinite',
};

export default function Welcome() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  
  const emailInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      
      if (!res.ok) throw new Error('Failed to send magic link');
      
      setSent(true);
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScrollToForm = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
    if (formRef.current) {
      formRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center',
        inline: 'nearest'
      });
    }
  };

  const handleScrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  };

  const billingLink = import.meta.env.VITE_STRIPE_BILLING_LINK;

  return (
    <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-900 to-zinc-800 relative overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
      {/* Gradient Accent */}
      <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-orange-500/20 via-purple-500/20 to-transparent rounded-full blur-3xl" />
      
      {/* Navigation */}
      <nav className="relative z-10 border-b border-white/10">
        <div className="container mx-auto px-4 h-16 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="h-8 w-8 bg-gradient-to-br from-orange-500 to-purple-600 rounded-lg" />
            <span className="text-white font-semibold">Shortcuts AI</span>
            <span className="text-orange-500">.com</span>
          </div>
          <div className="flex items-center space-x-8">
            <button 
              onClick={handleScrollToPricing}
              className="text-white/80 hover:text-white transition-colors"
            >
              Pricing
            </button>
            <a 
              href={billingLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-orange-400 hover:text-orange-300 transition-colors flex items-center"
            >
              Billing
              <ExternalLink className="ml-1 h-4 w-4" />
            </a>
          </div>
        </div>
      </nav>

      {/* Hero Section with Form */}
      <main className="relative">
        <div className="container mx-auto px-4 py-20 text-center">
          <div className="inline-flex items-center px-3 py-1 rounded-full bg-orange-500/10 text-orange-500 font-medium text-sm mb-8">
            #1 Ultra Apple Shortcuts
          </div>
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 max-w-4xl mx-auto">
            Supercharge Your iPhone
            <br />
            with Ultra Shortcuts.
          </h1>
          <p className="text-lg text-white/60 mb-4 max-w-2xl mx-auto">
            Unlock the Power of AI Automations: Choose a Shortcut or Subscribe for Unlimited Access.
          </p>
          <p className="text-sm text-white/40 mb-8">
            Powered by OpenAI
          </p>
          
          {/* Login Form */}
          <div className="max-w-md mx-auto" ref={formRef}>
            {sent ? (
              <div className="p-6 bg-zinc-800/50 border border-white/10 rounded-2xl">
                <p className="text-green-400">
                  Check your email for the magic link! It will expire in 15 minutes.
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4 p-6 bg-zinc-800/50 border border-white/10 rounded-2xl">
                <input
                  ref={emailInputRef}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Enter your email"
                  className="w-full px-4 py-3 text-lg text-white bg-white/20 rounded-full focus:outline-none focus:ring-2 focus:ring-orange-500 placeholder-white/60"
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-gradient-to-r from-orange-500 via-purple-600 to-pink-500 hover:from-orange-600 hover:via-purple-700 hover:to-pink-600 text-white rounded-full px-8 text-lg py-3"
                  style={gradientAnimation}
                >
                  {loading ? 'Sending...' : 'Create your shortcut with AI now →'}
                </button>
              </form>
            )}
          </div>
        </div>

        {/* Features Section */}
        <div className="container mx-auto px-4 py-20 grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="w-12 h-12 bg-gradient-to-br from-orange-500/20 to-purple-600/20 rounded-xl flex items-center justify-center mx-auto mb-4">
              <Tag className="w-6 h-6 text-orange-500" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">Get your Shortcut</h3>
            <p className="text-white/60">
              Select the shortcut you want to enhance your iPhone experience.
            </p>
          </div>
          
          <div className="text-center">
            <div className="w-12 h-12 bg-gradient-to-br from-orange-500/20 to-purple-600/20 rounded-xl flex items-center justify-center mx-auto mb-4">
              <Zap className="w-6 h-6 text-orange-500" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">Add it with a tap</h3>
            <p className="text-white/60">
              Easily integrate it into your Control Center or Lock Screen for quick access.
            </p>
          </div>
          
          <div className="text-center">
            <div className="w-12 h-12 bg-gradient-to-br from-orange-500/20 to-purple-600/20 rounded-xl flex items-center justify-center mx-auto mb-4">
              <Sparkles className="w-6 h-6 text-orange-500" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">Use It Effortlessly</h3>
            <p className="text-white/60">
              Activate your shortcut to enjoy seamless automation and convenience.
            </p>
          </div>
        </div>

        {/* Watch Section */}
        <div className="container mx-auto px-4 pb-20">
          <div className="flex items-baseline justify-center space-x-2 mb-8">
            <h2 className="text-3xl font-bold text-white">Watch a Shortcuts</h2>
            <span className="text-3xl font-bold text-orange-500">in Action</span>
          </div>
          <div className="max-w-4xl mx-auto rounded-2xl overflow-hidden">
            <video 
              className="w-full h-auto"
              autoPlay 
              loop 
              muted 
              playsInline
              aria-label="Demo video of Shortcuts in action"
            >
              <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* Pricing Section */}
        <div ref={pricingRef} className="container mx-auto px-4 pb-20">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Simple, Transparent Pricing</h2>
            <p className="text-xl text-white/60">Unlock unlimited shortcuts generation with AI</p>
          </div>
          
          <div className="max-w-md mx-auto">
            <div className="bg-zinc-800/50 border border-white/10 rounded-2xl p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-white">Unlimited Plan</h3>
                <div className="text-4xl font-bold text-white">$10<span className="text-xl font-normal text-white/60">/mo</span></div>
              </div>
              
              <ul className="space-y-3 mb-6">
                {[
                  "Unlimited AI-powered shortcut generations",
                  "Access to all premium shortcuts",
                  "Priority support",
                  "Early access to new features"
                ].map((feature, index) => (
                  <li key={index} className="flex items-center text-white">
                    <Check className="w-5 h-5 text-orange-500 mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>

              <button
                onClick={handleScrollToForm}
                className="w-full bg-gradient-to-r from-orange-500 via-purple-600 to-pink-500 hover:from-orange-600 hover:via-purple-700 hover:to-pink-600 text-white rounded-full px-8 text-lg py-3"
                style={gradientAnimation}
              >
                Get Started Now
              </button>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="container mx-auto px-4 pb-8 text-center">
          <p className="text-xs text-white/40">
            <a href="/legal" className="hover:text-white/60">Shortcuts AI™ is a registered trademark</a>. 
            Product of <a href="https://setox.io" className="text-orange-500 hover:text-orange-400">SETO X, LLC</a>. 
            ©2024. <a href="/legal" className="hover:text-white/60">Terms of Service and Privacy Policy</a>.
          </p>
        </div>
      </main>
    </div>
  );
}
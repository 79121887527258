export default function Legal() {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service and Privacy Policy</h1>
      <p className="text-gray-600 mb-8">Last updated: March 20, 2024</p>

      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-700 mb-4">
            Please read these Terms of Service ("Agreement" or "Terms of Service") carefully before using the services offered by AI-SHORTCUTS.COM. This agreement sets forth the legally binding terms and conditions for your use of the AI-SHORTCUTS.COM website and all related services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Acceptance of Terms</h2>
          <p className="text-gray-700 mb-4">
            By using our services in any manner, you agree to be bound by this Agreement. The Service is offered subject to acceptance without modification of all of these Terms of Service and all other operating rules, policies, and procedures that may be published from time to time.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. Service Description</h2>
          <p className="text-gray-700 mb-4">
            AI-SHORTCUTS.COM provides AI-powered Apple Shortcuts generation services. Our platform allows users to create and customize shortcuts for their iOS devices using artificial intelligence technology.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. User Obligations</h2>
          <div className="space-y-4">
            <p className="text-gray-700">You agree to:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Be at least 18 years old or have reached the legal age of majority in your jurisdiction</li>
              <li>Provide accurate and complete information when using our services</li>
              <li>Use the service only for lawful purposes</li>
              <li>Not attempt to circumvent any security features of the service</li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Subscription and Payments</h2>
          <p className="text-gray-700 mb-4">
            Subscriptions are billed on a recurring basis. You can cancel your subscription at any time through your account settings. Refunds are handled on a case-by-case basis and are not guaranteed.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">6. Privacy Policy</h2>
          <p className="text-gray-700 mb-4">
            We take your privacy seriously. Our privacy practices are designed to protect your personal information while providing you with the best possible service experience.
          </p>
          <div className="space-y-4">
            <h3 className="text-xl font-medium mb-2">Data Collection</h3>
            <p className="text-gray-700">We collect and process the following types of information:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Account information (email, usage data)</li>
              <li>Payment information (processed securely through Stripe)</li>
              <li>Service usage statistics</li>
              <li>Technical data (device information, IP address)</li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">7. Intellectual Property</h2>
          <p className="text-gray-700 mb-4">
            You retain ownership of any shortcuts you create using our service. However, AI-SHORTCUTS.COM retains all rights to our platform, technology, and services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
          <p className="text-gray-700 mb-4">
            AI-SHORTCUTS.COM is provided "as is" without any warranties. We are not liable for any damages arising from your use of our service.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">9. Contact Information</h2>
          <p className="text-gray-700 mb-4">
            For any questions about these terms or our service, please contact us at{' '}
            <a href="mailto:legal@ai-shortcuts.com" className="text-blue-600 hover:text-blue-800">
              legal@ai-shortcuts.com
            </a>
          </p>
        </section>
      </div>

      <footer className="mt-12 pt-8 border-t border-gray-200">
        <p className="text-sm text-gray-600 text-center">
          © {new Date().getFullYear()} AI-SHORTCUTS.COM. All rights reserved.
        </p>
      </footer>
    </div>
  );
} 
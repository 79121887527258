// src/App.tsx
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Welcome from './pages/Welcome'
import Verify from './pages/Verify'
import Chat from './pages/Chat'
import Inactive from './pages/Inactive'
import Subscribe from './pages/Subscribe'
import Legal from './pages/Legal'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/inactive" element={<Inactive />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/" element={<Navigate to="/welcome" replace />} />
      </Routes>
    </BrowserRouter>
  )
}
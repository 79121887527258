// src/pages/Verify.tsx
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export default function Verify() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const token = searchParams.get('token');
        if (!token) {
          setError('Invalid token');
          return;
        }

        const res = await fetch(`/api/auth/verify?token=${token}`);
        if (!res.ok) {
          const text = await res.text();
          setError(text);
          return;
        }

        // Token valid - redirect to chat
        navigate('/chat');
      } catch (e) {
        setError('Verification failed');
      }
    };

    verifyToken();
  }, [searchParams, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-red-50 p-4 rounded">
          <p className="text-red-800">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white p-4 rounded shadow">
        <p>Verifying...</p>
      </div>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';

interface User {
  id: string;
  email: string;
  subscription_status?: string;
}

export default function Subscribe() {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  
  const paymentLink = import.meta.env.VITE_STRIPE_PAYMENT_LINK;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await fetch('/api/auth/me');
        
        if (res.status === 401) {
          navigate('/welcome');
          return;
        }

        const userData = await res.json();
        setUser(userData);

        // Redirect if user already has a subscription status
        if (userData.subscription_status === 'active') {
          navigate('/chat');
        } else if (userData.subscription_status === 'canceled') {
          navigate('/inactive');
        }
      } catch (err) {
        setError('Failed to load user data');
        console.error(err);
      }
    };

    fetchUser();
  }, [navigate]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navigation />
        <div className="flex items-center justify-center pt-16">
          <div className="bg-red-50 p-4 rounded">
            <p className="text-red-800">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="flex items-center justify-center pt-16">
        <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-center mb-2">Get Started with CF Shortcuts</h1>
          {user && (
            <p className="text-center text-gray-600 mb-6">
              Welcome, {user.email}!
            </p>
          )}
          
          <div className="space-y-6">
            <div className="border rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Pro Plan</h2>
                <div className="text-2xl font-bold">$10/mo</div>
              </div>
              
              <ul className="space-y-3 mb-6">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  Unlimited AI-powered shortcuts
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  Priority support
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  Early access to new features
                </li>
              </ul>
              <a
                href={`${paymentLink}?prefilled_email=${encodeURIComponent(user?.email || '')}&client_reference_id=${encodeURIComponent(user?.id || '')}`}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Subscribe Now
              </a>
            </div>

            <div className="text-sm text-gray-500 text-center">
              Cancel anytime. No questions asked.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 